import "./App.css";
import { ZoomMtg } from "@zoom/meetingsdk";
import React from "react";
import { useState } from "react";
import axios from "axios";

ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
// loads language files, also passes any error messages to the ui
ZoomMtg.i18n.load("en-US");
ZoomMtg.i18n.reload("en-US");

function App() {
  const [inputValue, setInputValue] = useState("");

  var invalidEntry = false;
  var sdkKey = process.env.REACT_APP_ZOOM_CLIENT_ID;
  var meetingNumber = process.env.REACT_APP_ZOOM_MEETING_ID;

  var leaveUrl = "http://thestreetbible.com";

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const startMeeting = async (event) => {
    // Prevent the browser from reloading the page
    event.preventDefault();

    if (inputValue === "") {
      invalidEntry = true;
      return;
    }

    document.getElementById("zmmtg-root").style.display = "block";

    const res = await axios.get("/api/generateSignature");
    const data = res.data;

    ZoomMtg.init({
      leaveUrl: leaveUrl,
      defaultView: "gallery",
      success: (success) => {
        console.log(success);

        ZoomMtg.join({
          signature: data.signature,
          sdkKey: sdkKey,
          meetingNumber: meetingNumber,
          userName: inputValue,
          passWord: "122970",
          success: (success) => {
            console.log(success);
          },
          error: (error) => {
            console.log(error);
          },
        });
      },
      error: (error) => {
        console.log(error);
      },
    });
  };

  return (
    <div className='App bg-gray-900'>
      <div className='container'>
        <a href='https://thestreetbible.com' className='pointer-events-none'>
          <div className='pointer-events-auto flex items-center justify-between gap-x-6 bg-gray-900 px-6 py-2.5 sm:rounded-xl sm:py-3 sm:pl-4 sm:pr-3.5'>
            <p className='leading-6 text-white'>
              <strong className='font-semibold'>
                Go Back to The Street Bible
              </strong>
              <svg
                viewBox='0 0 2 2'
                className='mx-2 inline h-0.5 w-0.5 fill-current'
                aria-hidden='true'
              ></svg>
              <span aria-hidden='true'>&rarr;</span>
            </p>
          </div>
        </a>
        <div className='mx-auto'>
          <div className='relative isolate overflow-hidden bg-gray-900 px-6 py-24 shadow-2xl sm:rounded-3xl sm:px-24 xl:py-32'>
            <h2 className='md:mx-auto max-w-sm md:max-w-2xl lg:text-center text-3xl font-bold tracking-tight text-white sm:text-4xl'>
              Join Bible Study, 6 PM EST on Wednesday's.
            </h2>
            <form
              className='md:mx-auto mt-10 max-w-md gap-x-4'
              onSubmit={startMeeting}
            >
              <label className='sr-only'>Name</label>
              <input
                type='text'
                value={inputValue}
                onChange={handleChange}
                className='block w-full p-4 my-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                placeholder='Enter Name (REQUIRED)'
              />
              {invalidEntry ? (
                <p className='text-red-400'>Name Required</p>
              ) : (
                <div />
              )}
              <button className='block w-full rounded-md bg-white px-3.5 py-2.5 text-lg font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white'>
                Start
              </button>
              <p className='text-white mt-4'>
                For a better experience click below.
              </p>
              <a
                href='https://us06web.zoom.us/j/82764310049?pwd=mvA6aaKl9FvZ1oqctISA45ZHza9rWM.1'
                className='block text-center w-full my-4 rounded-md bg-blue-500 px-3.5 py-2.5 text-lg font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white'
              >
                <div>Open in Zoom</div>
              </a>
              {/* <p className='text-white mt-4'>If neither work visit youtube</p>
              <a
                href='https://us06web.zoom.us/j/82764310049?pwd=mvA6aaKl9FvZ1oqctISA45ZHza9rWM.1'
                className='block text-center w-full my-4 rounded-md bg-red-500 px-3.5 py-2.5 text-lg font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white'
              >
                <div>Open in Youtube</div>
              </a> */}
            </form>
            <svg
              viewBox='0 0 1024 1024'
              className='absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2'
              aria-hidden='true'
            >
              <circle
                cx={512}
                cy={512}
                r={512}
                fill='url(#759c1415-0410-454c-8f7c-9a820de03641)'
                fillOpacity='0.7'
              />
              <defs>
                <radialGradient
                  id='759c1415-0410-454c-8f7c-9a820de03641'
                  cx={0}
                  cy={0}
                  r={1}
                  gradientUnits='userSpaceOnUse'
                  gradientTransform='translate(512 512) rotate(90) scale(512)'
                >
                  <stop stopColor='#7775D6' />
                  <stop offset={1} stopColor='#E935C1' stopOpacity={0} />
                </radialGradient>
              </defs>
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
